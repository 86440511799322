<template>
  <div class="form-box card-container popbox-container" id="form-popup">
    <div class="columns">
      <div class="column"
        v-if="$store.getters.formSchema && $store.getters.formSchemaByName($store.getters.currentFormName) && !isLoading && workflowInfo">
        <expander :title="workflowInfo.display_name" @hasExpanded="handleDataPassed">
          <template v-slot:content>

            <div v-if="isDropdownExpanded" class=" has-text-black" :class="{ hidden: doHideWelcome }">
              <div class="" :style="{ padding: '20px' }">
                <!-- <div class="columns" style="border-bottom: 1px solid white">
              <div
                class=" title is-4"
                style="padding-bottom: 10px"
              >
                {{ workflowInfo.display_name }}

                 <span class=" subtitle is-6" style="font-style:italic;">(
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                 tempus quis placerat
                  ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet
                  fringilla. Nullam gravida purus diam, )
              </span> 
              </div>
            </div> -->



                <div class="banner-container-new">
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Process</span>
                <span class="span-header-text">{{
                  workflowInfo.name || "NA"
                }}</span>
              </div> -->
                  <justLable lableName="Process :" :lableValue="workflowInfo.name || 'NA'" icon="form.svg">
                  </justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Type</span>
                <span class="span-header-text">{{
                  workflowInfo.type || "NA" | title_case
                }}</span>
              </div> -->
                  <justLable lableName="Type :" :lableValue="workflowInfo.type || 'NA'" icon="lasttrnx.svg"></justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Verison</span>
                <span class="span-header-text">{{
                  workflowInfo.version || "NA"
                }}</span>
              </div> -->
                  <justLable lableName="Verison :" :lableValue="workflowInfo.version || 'NA'" icon="bundle.svg">
                  </justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Package</span>
                <span class="span-header-text">{{
                  workflowInfo.bundle_name || "NA"
                }}</span>
              </div> -->
                  <justLable lableName="Package :" :lableValue="workflowInfo.bundle_name || 'NA'" icon="workflow.svg">
                  </justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Publisher</span>
                <span class="span-header-text">{{
                  workflowInfo.author || "NA" | title_case
                }}</span>
              </div> -->
                  <justLable lableName="Publisher :" :lableValue="workflowInfo.author || 'NA'"
                    icon="workflow instace.svg"></justLable>

                  <!-- <DataTable
                class="cumtom-table"
                :value="wordflowDataArrP2"
                responsiveLayout="scroll"
                style="height: 140px;width: 100%;"
              >
                <Column field="instance" header="Instance"></Column>
                <Column field="state" header="State"></Column>
                <Column field="expires" header="Expires"></Column>
                <Column field="duration" header="Duration"></Column>
                <Column field="previous" header="Previous"></Column>
              </DataTable> -->
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Instance</span>
                <span class="span-header-text">
                  {{ workflowInfo.workflowId || "NA" }}</span
                >
              </div> -->
                  <justLable lableName="Instance :" :lableValue="workflowInfo.workflowId || 'NA'" icon="plane.svg">
                  </justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">State</span>
                <span class="span-header-text">{{
                  workflowInfo.current_state || "NA"
                }}</span>
              </div> -->
                  <justLable lableName="State :" :lableValue="workflowInfo.current_state || 'NA'" icon="pre-state.svg">
                  </justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Expires</span>
                <span class="span-header-text">{{
                  isExpired(workflowInfo.nearest_timeout)
                }}</span>
              </div> -->
                  <justLable lableName="Expires :" :lableValue="isExpired(workflowInfo.nearest_timeout)"
                    icon="time-remaining.svg"></justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Remaining</span>
                <span class="span-header-text">{{
                  workflowInfo.nearest_timeout | remaining_time
                }}</span>
              </div> -->
                  <justLable lableName="Remaining :" :lableValue="workflowInfo.nearest_timeout | remaining_time"
                    icon="age.svg"></justLable>
                  <!-- <div class="column  has-text-left">
                <span style="font-weight: 900">Signatory</span>
                <span class="span-header-text">{{
                  workflowInfo.lastTransaction.user || "NA"
                }}</span>
              </div> -->
                  <justLable lableName="Signatory :" :lableValue="workflowInfo.lastTransaction.user || 'NA'"
                    icon="lasttrax.svg"></justLable>
                </div>
              </div>
            </div>
          </template>
        </expander>
        <ncform v-if="renderComponent" :form-schema="$store.getters.formSchemaByName($store.getters.currentFormName)
          " :form-name="$store.getters.currentFormName" v-model="formData[$store.getters.currentFormName]">
        </ncform>
        <vue-bottom-sheet ref="bottomSheet" max-width="80%" max-height="16vh" :background-scrollable="true"
          :background-clickable="true" :overlay="false" :click-to-close="false">
          <div class="center-imp">
            <div class="columns">
              <div v-for="a in allowedActions" :key="a">
                <div class="column">
                  <button class="button is-rounded" style="border-color: rgb(18 19 156 / 54%); border-width: 3px;"
                    @click="action(a)">{{ a | humanize_string | title_case }}</button>
                </div>
              </div>
              <div v-if="$store.getters.formSubmitAllowed">
                <div class="column">
                  <button class="button is-newaccent is-rounded" style="background-color: rgb(18 19 156 / 54%);"
                    @click="submit($store.getters.currentFormName)">Submit</button>
                </div>
              </div>
            </div>
            <br />
          </div>
        </vue-bottom-sheet>
      </div>
      <div class="column" v-else>
        <div class="dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { globalFunction } from "@/services/global.function";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import justLable from "./justLable.vue";
import expander from './expander.vue';
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";

export default {
  data() {
    return {
      isLoading: false,
      formData: {},
      formKey: "",
      isFormDirty: false,
      doHideWelcome: false,
      isDropdownExpanded: false,
      renderComponent: true,
    };
  },
  async beforeCreate() { },
  components: {
    DataTable,
    Column,
    justLable,
    expander,
    VueBottomSheet
  },
  props: {},
  created() {
    this.formKey =
      this.$store.getters.currentFormName ||
      Object.keys(this.$store.getters.formSchema)[0] ||
      "";
    this.formData[this.formKey] = this.$store.getters.formData(this.formKey);
  },
  async mounted() {
    this.$store.subscribeAction({
      before: (action, state) => {
      },
      after: async (action, state) => {
        if (action.type === 'FORM_DATA') {
          console.log("updating form data");
          await this.forceRender();
        }
      }
    });
    if (this.$store.getters.formSubmitAllowed || this.allowedActions.length > 0) {
      this.$refs.bottomSheet.open();
    }
  },
  computed: {
    allowedActions() {
      return this.$store.getters.allowedActionList;
    },
    workflowInfo() {
      return this.$store.getters.getWorkFlowInfo;
    },
  },
  watch: {},
  methods: {
    async forceRender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Then, wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add MyComponent back in      
      this.formKey =
        this.$store.getters.currentFormName ||
        Object.keys(this.$store.getters.formSchema)[0] ||
        "";
      this.formData[this.formKey] = this.$store.getters.formData(this.formKey);
      this.renderComponent = true;
    },
    updateFormKey(value) {
      this.formKey = value;
      this.formData[this.formKey] = this.$store.getters.formData(this.formKey);
    },
    handleDataPassed(data) {
      this.isDropdownExpanded = data;
    },
    isExpired(val) {
      if (val == "NA") {
        return "No";
      } else {
        return "Yes";
      }
    },
    onChange({ paths, itemValue, formValue, itemOldValue }) {
      // paths: the path of the item whose value changes
      // itemValue: the latest value of the item whose value has changed
      // formValue: the latest value of the form
      // oldItemValue: ths old value of the item before value changed
      console.log(arguments);
    },
    humanize_string(input) {
      var r = input.replaceAll("_", " ");
      return r.toUpperCase();
    },
    async init() {

    },
    async submit(key) {
      var valid = await this.$ncformValidate(key);
      if (valid.result) {
        this.isLoading = true;
        this.$emit("exit", true);
        // this.$root.$emit("select_card", 0);
        await this.$store.dispatch("SUBMIT_FORM", {
          data: this.formData[key],
        });
        this.isLoading = false;
        this.$root.$emit("close_form");
        globalFunction.notify("is-success", "Form submitted successfully");
      } else {
        globalFunction.error(
          "Validation Failed. Please check failing fields and retry."
        );
        // var errMsg = "Please check the following fields <br/>";
        // if (!!valid.detail && Array.isArray(valid.detail)) {
        //   valid.detail.forEach(element => {
        //     if (!element.result.result) {
        //       console.log("feild failing:", element.__dataPath);
        //       const parts = element.__dataPath.split(".");
        //       var elem = parts[parts.length - 1];
        //       elem = elem.charAt(0).toLowerCase() + elem.slice(1);
        //       var idx = elem.search(/[A-Z]/)
        //       var words = []
        //       while (idx !== -1) {
        //         words.push(elem.slice(0, idx))
        //         if (idx == elem.length - 1) {
        //           elem = elem.charAt(idx).toLowerCase()
        //         } else {
        //           elem = elem.charAt(idx).toLowerCase() + elem.slice(idx + 1)
        //         }
        //         idx = elem.search(/[A-Z]/)
        //       }
        //       words.push(elem);
        //       for (var i = 0; i < words.length; i++) {
        //         words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        //       }
        //       const realElem = words.join(" ");
        //       errMsg = errMsg + realElem + "<br/>"
        //     }
        //   });
        //   globalFunction.error(errMsg, "body")
        // } else {
        //   console.log("skipping submit form as validation failed and no details found!");
        // }
      }
    },
    async action(option) {
      this.isLoading = true;
      this.$emit("exit", true);
      // this.$root.$emit("select_card", 0);
      await this.$store.dispatch("USER_ACTION", { action: option });
      this.isLoading = false;
      this.$root.$emit("close_form");
      globalFunction.notify("is-success", "Action successful");
    },
  },
  destroyed() {
    this.$root.$emit("close_form");
  }
};
</script>
